import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { LOAD_INIT_QUESTION, LOAD_NEXT_QUESTION, LOAD_CATEGORIES, NextQue } from "../graphQL/queries";
import { ERROR_HANDLE, SUBMIT_QUESTION } from "../graphQL/mutations";
import { useSelector, useDispatch } from "react-redux";
// import { Notifications } from 'react-push-notification';
import { ToastContainer, toast } from 'react-toastify';
import * as Actions from '../store/actions';
import { Steps } from 'rsuite';
import NestedNode from './nestedQue';
import thankyou from '../asset/frames/thankyou.png';
// import { category } from './json_category'

const TravelQuestions = (props: any) => {
    const { Questions, Summary, ParamLocation } = useSelector((state: any) => state.Question);
    const dispatch = useDispatch();
    const messagesEndRef = useRef(null)
    const [flagQue, setFlagQue] = useState(false)
    const [vertical, setVertical] = useState(false)
    const [dateFlexFlag, setDateFlexFlag] = useState(false)
    const [flexibleDate, setFlexibleDate] = useState(0);
    const [rooms, setRooms] = useState(1);
    const [currentStep, setCurrentStep] = useState(-1);
    const [nextNodeID, setNextNodeID] = useState('');
    const [adultsCount, setAdultsCount] = useState(2);
    const [childrensCount, setChildrensCount] = useState(0);
    const [childrensDetails, setChildrensDetails] = useState([]);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [showSummery, setShowSummery] = useState(false);
    const [submitAPIFlag, setSubmitAPIFlag] = useState(false);
    const [readyToShowSummery, setReadyToShowSummery] = useState(false);
    const [airSelectedStatus, setAirSelectedStatus] = useState(false);
    const [totalDaysSelected, setTotalDaysSelected] = useState(1)
    const [category, setCategory] = useState([]);
    const [toggleHover, setToggleHover] = useState(false);
    const [roomArraangement, setRoomArraangement]: any = useState([{
        room_no: 0,
        adults: 2,
        childrens: 0,
        childOneAge: -1,
        childTwoAge: -1,
        childThreeAge: -1
    }]);

    // const [initData, setInitData] = useState({});
    // const loading = true;
    var endDate = new Date();
    endDate.setDate(endDate.getDate() + 4);
    const [selectionRange, setSelectionRange] = useState({
        startDate: endDate,
        endDate: endDate,
        key: 'selection',
    });
    const [ageGroup, setAgeGroup] = useState(['Under 1', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]);
    const [displayActiveId, setDisplayActiveId] = useState('');
    const { data: Category } = useQuery(LOAD_CATEGORIES);
    const { data: initData, loading } = useQuery(flagQue ? LOAD_NEXT_QUESTION : LOAD_INIT_QUESTION, {
        variables: { id: nextNodeID }
    });
    // const { data: initData, loading } = NextQue(nextNodeID);
    // const findNextQue = async (id: any) => {
    //     const data = await NextQue(id);
    //     updateDOM(data);
    // }
    // const Destination = new URLSearchParams(props.location.search).get("loc");
    // console.log("Destination",Destination);
    const [errorHandle] = useMutation(ERROR_HANDLE);
    const [updateData,{ error }] = useMutation(SUBMIT_QUESTION ,{
        onError: (error) => {
            var ChildrenAges = '';
            if(childrensCount > 0){
                for(var i = 0; i < roomArraangement.length; i++){
                    
                    if(roomArraangement[i].childOneAge >= 0){
                        
                        if(ChildrenAges !== '') {
                            ChildrenAges += ', ';
                        }
                        ChildrenAges += roomArraangement[i].childOneAge.toString();
                        // console.log("ChildrenAges 1111111",roomArraangement[i].childOneAge,ChildrenAges);
                    }
                    // console.log("ChildrenAges",roomArraangement[i].childTwoAge, ChildrenAges);
                    if(roomArraangement[i].childTwoAge >= 0){
                        if(ChildrenAges !== '') {
                            ChildrenAges += ', ';
                        }
                        ChildrenAges += roomArraangement[i].childTwoAge.toString();
                    }
                    // console.log("ChildrenAges",roomArraangement[i].childThreeAge, ChildrenAges);
                    if(roomArraangement[i].childThreeAge >= 0){
                        if(ChildrenAges !== '') {
                            ChildrenAges += ', ';
                        }
                        ChildrenAges += roomArraangement[i].childThreeAge.toString();
                    }
                    // console.log("ChildrenAges",ChildrenAges);
                }
            }
            console.log("mutationData",error); // the error if that is the case
            var summary = `${Summary.name.value ? Summary.name.value : ''}, You are going to the ${Summary.destination.value ? Summary.destination.value : ''}
            from ${Summary.from.value ? Summary.from.value : ''}, during the dates from ${Summary.startEndDate.value?.startDate} to ${Summary.startEndDate.value?.endDate}.
            You are going to travel in a group of ${childrensCount + adultsCount} people:
            ${adultsCount} Adult${adultsCount > 1 ? 's' : ''}:${childrensCount} Children${childrensCount > 1 ? 's' : ''}
            ${childrensCount > 0 ? 'of age '+ ChildrenAges:null}.
            Your total trip budget is up to ${Summary.budget.value ? Summary.budget.value : ''}
            
            You are going on a ${
                Summary.occasion.value ? 
                Summary.occasion.value.length === 1 && Summary.occasion.value[0]=== 'Other' ?
                    'Vacation' :
                    Summary.occasion.value ? Summary.occasion.value.filter((n:any) => {
                        return n !== 'Other';
                    }).map((node: any, index: any) => {
                    return (index !== 0 ? Summary.occasion.value.filter((n:any) => {
                        return n !== 'Other';
                    }).length === index + 1 ? ' and ' : ', ' : '') + node
                }) : '' : ''
            } to enjoy ${Summary.accomodationPreferences.value ? Summary.accomodationPreferences.value.map((node: any, index: any) => {
                    return (index !== 0 ? Summary.accomodationPreferences.value.length === index + 1 ? ' and ' : ', ' : '') + node
                }) : ''
            }. You prefer ${Summary.stay.value ? Summary.stay.value.map((node: any, index: any) => {
                    return (index !== 0 ? Summary.stay.value.length === index + 1 ? ' and ' : ', ' : '') + node
                }) : ''
            }
            
            Your email id is ${Summary.email.value} and your Phone Number is ${Summary.phone.value}`;
            let Arraangement: any = [];
            roomArraangement.map((node: any) => {
                Arraangement.push({
                    number: node.room_no,
                    adults: node.adults,
                    children: node.childrens,
                    childrenAge: [node.childOneAge, node.childTwoAge, node.childThreeAge],
                });
            })
            errorHandle({
                variables: {
                    answerJson:JSON.stringify({
                        name: Summary.name.value,
                        phone: Summary.phone.value,
                        email: Summary.email.value,
                        from: Summary.from.value,
                        destination: Summary.destination.value,
                        startDate: Summary.startEndDate.value?.startDate,
                        endDate: Summary.startEndDate.value?.endDate,
                        adultsCount: adultsCount,
                        budget: Summary.budget.value[0],
                        vacationType: Summary.occasion.value,
                        submitTime: new Date().toISOString(),
                        roomRequirement: Summary.roomPreference.value,
                        resortRquirements: Summary.accomodationPreferences.value,
                        previouslyStayedResortInfo: Summary.previousHotels.value.length === 1 && Summary.previousHotels.value[0]=== 'Other' ? 'Family Vacation': Summary.previousHotels.value,
                        kidsCount: childrensCount,
                        interests: Summary.interests.value,
                        importantInfo: Summary.importanInfo.value,
                        comment: Summary.comment.value,
                        bookingItems: Summary.bookingHelp.value,
                        roomInfo: Arraangement,
                        amenities: Summary.amenities.value,
                        tripDateFelxibility: flexibleDate,
                        summary: summary
                    }),
                    errorJson: JSON.stringify(error)
                }
            });
        }
    });
    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [
        window.innerWidth
    ]);
    const updateSize = () => {
        if (window.innerWidth <= 752) {
            setVertical(false);
        } else {
            setVertical(true);
        }
    }

    const submitData = () => {
        if (!submitAPIFlag) {
            setSubmitAPIFlag(true);
            let Arraangement: any = [];
            roomArraangement.map((node: any) => {
                let data = [node.childOneAge, node.childTwoAge, node.childThreeAge];
                data = data.filter(val => val !== -1);
                Arraangement.push({
                    number: node.room_no,
                    adults: node.adults,
                    children: node.childrens,
                    childrenAge: data,
                });
            })
            var ChildrenAges = '';
            if(childrensCount > 0){
                for(var i = 0; i < roomArraangement.length; i++){
                    
                    if(roomArraangement[i].childOneAge >= 0){
                        
                        if(ChildrenAges !== '') {
                            ChildrenAges += ', ';
                        }
                        ChildrenAges += roomArraangement[i].childOneAge.toString();
                        // console.log("ChildrenAges 1111111",roomArraangement[i].childOneAge,ChildrenAges);
                    }
                    // console.log("ChildrenAges",roomArraangement[i].childTwoAge, ChildrenAges);
                    if(roomArraangement[i].childTwoAge >= 0){
                        if(ChildrenAges !== '') {
                            ChildrenAges += ', ';
                        }
                        ChildrenAges += roomArraangement[i].childTwoAge.toString();
                    }
                    // console.log("ChildrenAges",roomArraangement[i].childThreeAge, ChildrenAges);
                    if(roomArraangement[i].childThreeAge >= 0){
                        if(ChildrenAges !== '') {
                            ChildrenAges += ', ';
                        }
                        ChildrenAges += roomArraangement[i].childThreeAge.toString();
                    }
                    // console.log("ChildrenAges",ChildrenAges);
                }
            }
            // console.log("ChildrenAges",ChildrenAges);
            var summary = `${Summary.name.value ? Summary.name.value : ''}, You are going to the ${Summary.destination.value ? Summary.destination.value : ''}
            from ${Summary.from.value ? Summary.from.value : ''}, during the dates from ${Summary.startEndDate.value?.startDate} to ${Summary.startEndDate.value?.endDate}.
            You are going to travel in a group of ${childrensCount + adultsCount} people:
            ${adultsCount} Adult${adultsCount > 1 ? 's' : ''}:${childrensCount} Children${childrensCount > 1 ? 's' : ''}
            ${childrensCount > 0 ? 'of age '+ ChildrenAges:null}.
            Your total trip budget is up to ${Summary.budget.value ? Summary.budget.value : ''}
            
            You are going on a ${
                Summary.occasion.value ? 
                Summary.occasion.value.length === 1 && Summary.occasion.value[0]=== 'Other' ?
                    'Vacation' :
                    Summary.occasion.value ? Summary.occasion.value.filter((n:any) => {
                        return n !== 'Other';
                    }).map((node: any, index: any) => {
                    return (index !== 0 ? Summary.occasion.value.filter((n:any) => {
                        return n !== 'Other';
                    }).length === index + 1 ? ' and ' : ', ' : '') + node
                }) : '' : ''
            } to enjoy ${Summary.accomodationPreferences.value ? Summary.accomodationPreferences.value.map((node: any, index: any) => {
                    return (index !== 0 ? Summary.accomodationPreferences.value.length === index + 1 ? ' and ' : ', ' : '') + node
                }) : ''
            }. You prefer ${Summary.stay.value ? Summary.stay.value.map((node: any, index: any) => {
                    return (index !== 0 ? Summary.stay.value.length === index + 1 ? ' and ' : ', ' : '') + node
                }) : ''
            }
            
            Your email id is ${Summary.email.value} and your Phone Number is ${Summary.phone.value}`;
            console.log("ChildrenAges",ChildrenAges, summary);
            updateData({
                variables: {
                    name: Summary.name.value,
                    phone: Summary.phone.value,
                    email: Summary.email.value,
                    from: Summary.from.value,
                    destination: Summary.destination.value,
                    startDate: Summary.startEndDate.value?.startDate,
                    endDate: Summary.startEndDate.value?.endDate,
                    adultsCount: adultsCount,
                    budget: Summary.budget.value[0],
                    vacationType: Summary.occasion.value,
                    submitTime: new Date().toISOString(),
                    roomRequirement: Summary.roomPreference.value,
                    resortRquirements: Summary.accomodationPreferences.value,
                    previouslyStayedResortInfo: Summary.previousHotels.value.length === 1 && Summary.previousHotels.value[0]=== 'Other' ? 'Family Vacation': Summary.previousHotels.value,
                    kidsCount: childrensCount,
                    bookingItems: Summary.bookingHelp.value,
                    interests: Summary.interests.value,
                    importantInfo: Summary.importanInfo.value,
                    comment: Summary.comment.value,
                    roomInfo: Arraangement,
                    amenities: Summary.amenities.value,
                    tripDateFelxibility: flexibleDate,
                    summary: summary
                }
            }).finally(() => {
                setSubmitStatus(true);
                setSubmitAPIFlag(false);
                toast.success('Submitted Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch((e:any)=>{
                // ERROR_HANDLE
            })
            // setSubmitStatus(false);
            // setSubmitAPIFlag(false);
        }
    }
    const setNextDisplay = (id: any) => {
        const index = Questions.findIndex((data: { id: any; }) => data.id === id);
        // console.log("1234567890", Questions[index]?.hide);
        // if (Questions[index]?.hide === 0) {
        //     id = Questions[index+1].id;
        // }
        setDisplayActiveId(id)
        if (Questions[index]) {
            setCurrentStep(category.findIndex((data: { name: any; }) => data.name === Questions[index].questionGroup));
        }
    }
    const moveToBack = (index: any) => {
        if (index >= 2) {
            let nIndx = index - 2
            if (index === Questions.length && !Questions[index - 1].nextQuestion && showSummery) {
                nIndx = index - 1;
                setShowSummery(false);
            }
            console.log('123456789012', Questions[nIndx])
            if (Questions[nIndx].hide === 0) {
                nIndx--;
            }
            setDisplayActiveId(Questions[nIndx].id)
            setCurrentStep(category.findIndex((data: { name: any; }) => data.name === Questions[nIndx].questionGroup));
        }
    }

    const updateDOM = async (initData: any) => {
        if (initData) {
            // @ts-ignore
            const dupl = Questions.filter((node: { id: any }) => node.id === initData?.queryTravelQuestion[0].id)
            if (Questions && dupl.length === 0 && initData?.queryTravelQuestion.length >= 1) {
                if (category && currentStep != -1) {
                    // @ts-ignore
                    setCurrentStep(category.findIndex((data: { name: any; }) => data.name === initData?.queryTravelQuestion[0].questionGroup));
                }
                let HideFlag: any = 1;
                console.log("1234567890", airSelectedStatus, initData?.queryTravelQuestion[0].key)
                if (initData?.queryTravelQuestion[0].key === 'departureAirport' && !airSelectedStatus) {
                    let dd = initData?.queryTravelQuestion[0];
                    HideFlag = 0;
                    console.log("1234567890", airSelectedStatus, dd)
                    if (dd.nextQuestion.id && Summary.destination.value !== '') {
                        console.log("1234567890 FLAG",)
                        setNextDisplay(dd.nextQuestion.id);
                        setNextNodeID(dd.nextQuestion.id);
                        setFlagQue(true);
                    }
                } else {
                    // @ts-ignore
                    setNextNodeID(initData?.queryTravelQuestion[0].id);
                    // @ts-ignore
                    setDisplayActiveId(initData?.queryTravelQuestion[0].id);
                }
                // @ts-ignore
                await dispatch(Actions.appendQuestion(initData?.queryTravelQuestion, Questions[Questions.length - 1], HideFlag))
            }
        }
    }
    const updateCategory = async () => {
        if (Category) {
            setCategory(Category?.__type?.enumValues);
        }
    }
    const handleGotoStep = (node: any, key: number) => {
        const grp: any = Questions.filter((data: { questionGroup: any; }) => data.questionGroup === node.name);
        if (grp.length >= 1) {
            setCurrentStep(key);
            setShowSummery(false);
            if (ParamLocation != '' && key === 0) {
                if (grp[1]) {
                    setDisplayActiveId(grp[1].id);
                }
            } else {
                setDisplayActiveId(grp[0].id);
            }
        } else if (category.length - 1 === key && readyToShowSummery) {
            setCurrentStep(category.length - 1);
            setShowSummery(true);
        }
    }
    
    const getStart = () => {
        if (Questions[0]?.nextQuestion) {
            console.log("nextQuestion", Summary.destination.value);
            if (Questions[0].nextQuestion.id && Summary.destination.value !== '') {
                setNextDisplay(Questions[0].nextQuestion.id);
                setNextNodeID(Questions[0].nextQuestion.id);
                setFlagQue(true);
            }
        }
        setCurrentStep(0);
    }
    useEffect(() => {
        updateDOM(initData);
    }, [initData]);
    useEffect(() => {
        updateCategory();
        dispatch(Actions.findLocation());
        // submitData();
    }, [Category])
    let index = 0;
    return (
        <div>
            <div className="mainHeaderBG"></div>
            <div className="mainLogo"></div>
            <ToastContainer />
            {submitStatus ?
                <div className="thankscenter">
                    <img className="thanksImg" src={thankyou}></img>
                    <div className="thanks">Thank You!</div>
                    <div className="thanksinfo">We are building a personalized itinerary based on your choices and your trip designer will be in touch with you within 24 hours. You can always make this trip better by discussing changes to accomodations, flights or activities with your designer. </div>
                </div>
                :
                <div className="center Box">
                    <div className={vertical ? "left" : "hide"} >
                        <Steps current={currentStep} vertical={vertical}>
                            {
                                category ? category.map((node: any, key: any) => {
                                    return <Steps.Item onClick={() => handleGotoStep(node, key)} key={key} className="Step" title={node.name.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")} />
                                }) : null
                            }
                            {/* <Steps.Item className="Step" onClick={() => setCurrentStep(0)} title="Contact Info" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(1)} title="Basic Trip Details" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(2)} title="Flight" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(3)} title="Accommodations" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(4)} title="Occasion" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(5)} title="Interests" />
                        <Steps.Item className="Step" onClick={() => setCurrentStep(6)} title="Summary" /> */}
                        </Steps>
                    </div>
                    <div className={vertical ? "right" : "full"}>
                        {currentStep < 0 ?
                            <div className="header">
                                {/* <div className="subLog"></div> */}
                                <div className="headTitle">Share your travel preferences so that a trip designer with intimate knowledge of your destination can plan your next getaway</div>
                                <span onClick={() => getStart()} className="NextButton">Get Started</span>
                            </div>
                            : (
                                <div>
                                    {/* {loading ? "T" : "F"} */}
                                    <div className={loading ? "loaderMain " : "displayNone"}>
                                        <div className="loader">
                                        </div>
                                    </div>
                                    {
                                        Questions ? Questions.map((node: any, key: any) => {
                                            index++;
                                            return displayActiveId === node.id ? <div key={key}>
                                                <NestedNode
                                                    key={key}
                                                    index={index}
                                                    moveToBack={moveToBack}
                                                    setFlagQue={setFlagQue}
                                                    loading={loading}
                                                    Questions={Questions}
                                                    QuestionsLength={Questions.length}
                                                    displayActiveId={displayActiveId}
                                                    setDisplayActiveId={setDisplayActiveId}
                                                    setNextDisplay={setNextDisplay}
                                                    nextNodeID={nextNodeID}
                                                    ageGroup={ageGroup}
                                                    airSelectedStatus={airSelectedStatus}
                                                    setAirSelectedStatus={setAirSelectedStatus}
                                                    setDateFlexFlag={setDateFlexFlag}
                                                    dateFlexFlag={dateFlexFlag}
                                                    setFlexibleDate={setFlexibleDate}
                                                    flexibleDate={flexibleDate}
                                                    selectionRange={selectionRange}
                                                    setSelectionRange={setSelectionRange}
                                                    totalDaysSelected={totalDaysSelected}
                                                    setTotalDaysSelected={setTotalDaysSelected}
                                                    setCurrentStep={setCurrentStep}
                                                    setNextNodeID={setNextNodeID}
                                                    adultsCount={adultsCount}
                                                    setAdultsCount={setAdultsCount}
                                                    childrensCount={childrensCount}
                                                    setChildrensCount={setChildrensCount}
                                                    childrensDetails={childrensDetails}
                                                    setChildrensDetails={setChildrensDetails}
                                                    Summary={Summary}
                                                    submitAPIFlag={submitAPIFlag}
                                                    submitData={submitData}
                                                    category={category}
                                                    node={node}
                                                    toggleHover={toggleHover}
                                                    setToggleHover={setToggleHover}
                                                    setRooms={setRooms}
                                                    showSummery={showSummery}
                                                    setShowSummery={setShowSummery}
                                                    roomArraangement={roomArraangement}
                                                    setRoomArraangement={setRoomArraangement}
                                                    setReadyToShowSummery={setReadyToShowSummery}
                                                    rooms={rooms}>
                                                </NestedNode>
                                            </div> : null
                                        }) : null}
                                </div>
                            )
                        }
                        <div className="" ref={messagesEndRef} />
                    </div>
                </div>
            }
            {!submitStatus ?
                <div className={vertical ? "hide" : "show"} >
                    <Steps current={currentStep} vertical={vertical}>
                        {
                            category ? category.map((node: any, key: any) => {
                                return <Steps.Item key={key} onClick={() => handleGotoStep(node, key)} className="Step" />
                            }) : null
                        }
                    </Steps>
                </div>
                : null
            }
        </div>
    );
}
export default TravelQuestions;