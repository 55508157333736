import { gql } from '@apollo/client';
import { JSON } from '../components/json';
export const LOAD_INIT_QUESTION = gql`
query MyQuery {
    queryTravelQuestion(filter: {isFirstQuestion: true}) {
      id
      questionInfo
      invalidResponse
      isFirstQuestion
      key
      placeHolder
      answerChoices {
        id
        sortOrder
        tooltip
        isAlwaysSelected
        displayRow
        value
      }
      question
      skippable
      subQuestions {
        id
      }
      defaultValue
      validResponse
      answerType
      questionGroup
      nextQuestion {
        id
      }
    }
  }
`;

export const LOAD_NEXT_QUESTION = gql`query MyQuery($id: [ID!]) {
  queryTravelQuestion(filter: {id: $id}) {
    id
    questionInfo
    invalidResponse
    isFirstQuestion
    key
    placeHolder
    answerChoices {
      id
      sortOrder
      tooltip
      isAlwaysSelected
      displayRow
      value
    }
    question
    skippable
    subQuestions {
      id
    }
    defaultValue
    validResponse
    answerType
    questionGroup
    nextQuestion {
      id
    }
  }
}`;
export const LOAD_CATEGORIES = gql`{
  __type(name: "QuestionGroup") {
    enumValues {
      name
    }
  }
}`;

export const NextQue = (id: any) => {
  let data: any;
  if (id === '') {
    data = JSON.filter((node) => {
      return node.isFirstQuestion === true
    })
  } else {
    data = JSON.filter((node) => {
      return node.id === id
    })
  }

  if (data[0].subQuestions) {
    console.log("NextQUEJSON 4", data[0]?.subQuestions)
    data[0].subQueList = data[0]?.subQuestions.map((node: any) => {
      console.log("NextQUEJSON 3", node)
      const list = JSON.filter((n) => {
        return n.id === node.id
      })
      console.log("NextQUEJSON 2", list[0])
      return list[0];
    })
  }
  console.log("NextQUEJSON ", data[0]);
  if (data) {
    return {
      queryTravelQuestion: [data[0]]
    };
  } else {
    return []
  }

}