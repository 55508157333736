import * as Actions from '../actions';

const initialState = {
    Questions: [],
    ParamLocation: '',
    Summary: {
        name: {
            key: "firstNameLastName",
            value: ''
        },
        phone: {
            key: "phone",
            value: ''
        },
        email: {
            key: "email",
            value: ''
        },
        bookingHelp:{
            key:"bookingHelp",
            value:''
        },
        from: {
            key: "departureAirport",
            value: ''
        },
        destination: {
            key: "destination",
            value: ''
        },
        occasion:{
            key:"tripOccasion",
            value:''
        },
        startEndDate: {
            key: "tripDateRange",
            value: {}
        },
        budget: {
            key: "tripBudget",
            value: ''
        },
        passangers: {
            key: "tripPassengers",
            value: ''
        },
        interests: {
            key: "activities",
            value: []
        },
        comment: {
            key: "comment",
            value: ''
        },
        importanInfo: {
            key: "0x25892b5c9",
            value: ''
        },
        stay:{
            key:"stay",
            value:''
        },
        accomodationPreferences:{
            key:"accomodationPreferences",
            value:''
        },
        roomPreference:{
            key:"roomPreferences",
            value:''
        },
        previousHotels:{
            key:"previousHotels",
            value:''
        },
        amenities: {
            key: "0x25892b4dc",
            value: ''
        }
    }
}
const questionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.SET_QUESTIONS:
            return {
                ...state,
                Questions: action.Questions
            }
        case Actions.UPDATE_SUMMARY:
            return {
                ...state,
                Summary: action.Summary
            }
        case Actions.LOCATION:
            return {
                ...state,
                ParamLocation: action.ParamLocation
            }
        default: // need this for default case
            return state
    }
}
export default questionReducer;