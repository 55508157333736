import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Actions from '../store/actions'
// import { TextField } from '@mui/material';
import logo from '../asset/logo.png'
import ModalLayout from './modals/modalLayout'
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { DateRange } from 'react-date-range';
import Moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import SubQuestions from './subQuestions';
import 'react-phone-input-2/lib/style.css'
import { isArray } from "util";
const NestedQue = (props: any) => {
    const { node, index, setFlagQue, moveToBack, ageGroup, adultsCount, setAdultsCount,
        childrensCount, setChildrensCount, setCurrentStep, Summary, submitData,
        childrensDetails, setChildrensDetails, displayActiveId, selectionRange, setSelectionRange,
        setDisplayActiveId, setNextDisplay, nextNodeID, setNextNodeID, QuestionsLength, Questions,
        findNextQue, dateFlexFlag, setDateFlexFlag, showSummery, setShowSummery, category,
        setFlexibleDate, flexibleDate, setRooms, rooms, airSelectedStatus, setAirSelectedStatus,
        roomArraangement, setRoomArraangement, setReadyToShowSummery, submitAPIFlag, totalDaysSelected, 
        setTotalDaysSelected, toggleHover, setToggleHover } = props;
    const dispatch = useDispatch();
    const [validation, setValidation] = useState('');
    const handleAnsSelection = async (key: any, node: any, parent: any, naming = '') => {
        console.log("updateValues", node);
        setValidation("");
        
        if (node.answerType === 'MultipleChoice' || node.answerType === 'MultipleChoiceTextInput') {
            await dispatch(Actions.updateSelections(key, node, parent))
        } else if (node.answerType === 'SingleChoice') {
            await dispatch(Actions.updateSingleSelections(key, node, parent))
        } else if (node.answerType === 'TextInput' || node.answerType === 'FirstNameLastName' || node.answerType === 'Toggler' || node.answerType === 'TextAreaInput' || node.answerType === 'Email' || node.answerType === 'Phone' || node.answerType === 'Passengers') {
            await dispatch(Actions.updateValues(key, node, parent, naming))
        }
        if (node.key === "bookingHelp") { //0x25892b4e1
            const ans = node.selectedAns.filter((data: any) => data === "Air");
            if (ans.length >= 1) {
                await setAirSelectedStatus(true);
                console.log("updateValuesYYYYY", node);
                await dispatch(Actions.updateAir('departureAirport', 0))
            } else {
                await setAirSelectedStatus(false);
                console.log("updateValuesNNNNN", node);
                await dispatch(Actions.updateAir('departureAirport', 1))
            }
        }
    }
    const handleFlexibleDate = async (key: number, node: any) => {
        await setFlexibleDate(flexibleDate === 0 && key === -1 ? 0 : flexibleDate === 3 && key === 1 ? 3 : flexibleDate + key)
        handleAnsSelection(adultsCount + childrensCount, node, {});
    }
    const handleRooms = async (key: number, node: any) => {
        setRooms(rooms === 1 && key === -1 ? 1 : rooms + key)
        await handleAnsSelection(rooms, node, {});
    }
    const handleToggleHover = async (flag: boolean) => {
        setToggleHover(flag)
    }
    const setAnsSelection = async (node: any) => {
        console.log("setAnsSelection", node);
        if (node.key === "bookingHelp") { //0x25892b4e1
            const ans = node.selectedAns.filter((data: any) => data === "Air");
            if (ans.length >= 1) {
                await setAirSelectedStatus(true);
                await dispatch(Actions.updateAir('departureAirport', 1))
            } else {
                await setAirSelectedStatus(false);
                await dispatch(Actions.updateAir('departureAirport', 0))
            }
        }
        if (node.answerType === 'Passengers') {
            node.selectedAns = adultsCount + childrensCount;
            console.log("setAnsSelection", node);
            dispatch(Actions.updateValues(adultsCount + childrensCount, node, {}))
            updateData(node);;
        } else if (node?.answerType === 'FirstNameLastName') {
            if (!node.selectedAns1 || !node.selectedAns2) {
                setValidation("Please enter valid first name and last name");
            } else if (node.selectedAns1 && node.selectedAns1 === '') {
                setValidation("Please enter valid first name");
            } else if (node.selectedAns2 && node.selectedAns2 === '') {
                setValidation("Please enter valid last name");
            } else {
                updateData(node);
            }
        } else if (!node.skippable && (!node.selectedAns || node?.selectedAns === null)) {
            setValidation("Please enter / select valid data");
        }else if (!node.skippable && (Array.isArray(node?.selectedAns)) && node?.selectedAns.length === 0) {
            setValidation("Please select valid data");
        } else if (!node.skippable && node?.selectedAns.length <= 1 && (node.answerType === 'TextInput' || node.answerType === 'FirstNameLastName' || node.answerType === 'TextAreaInput')) {
            setValidation("Please enter / select valid data");
        } else if (node?.answerType === 'Email') { //|| )
            if (!RegExp(/[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,15}/g).test(node?.selectedAns)) {
                setValidation("Invalid email ID");
            } else if (!node.subQueList[0].selectedAns || node.subQueList[0].selectedAns <= 8) {
                setValidation("Invalid phone number");
            } else {
                updateData(node);
            }
        } else {
            console.log("setAnsSelection", node);
            updateData(node);
        }
    }
    const updateData = (node: any) => {
        console.log('1234567890', node)
        setValidation("");
        // if(node.)
        if (node.nextQuestion) {
            if (node.nextQuestion.id) {
                if (QuestionsLength === index) {
                    setNextDisplay(node.nextQuestion.id)
                    setNextNodeID(node.nextQuestion.id);
                } else {
                    console.log('1234567890', node)
                    if (Questions[index].hide === 0) {
                        setNextDisplay(Questions[index].nextQuestion.id)
                    } else {
                        setNextDisplay(node.nextQuestion.id)
                    }
                }
                setFlagQue(true);
            }
            setShowSummery(false);
            dispatch(Actions.updateSummary());
        } else {
            dispatch(Actions.updateSummary());
            setCurrentStep(category.length - 1);
            setReadyToShowSummery(true);
            setShowSummery(true);
        }
    }
    const handleSelect = (ranges: any) => {
        // totalDaysSelected, setTotalDaysSelected
        let date1: Date = new Date(ranges.selection.startDate);
        let date2: Date = new Date(ranges.selection.endDate);
        let timeInMilisec: number = date2.getTime() - date1.getTime();
        let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
        setTotalDaysSelected(daysBetweenDates+1);
        setSelectionRange({
            startDate: new Date(ranges.selection.startDate),
            endDate: new Date(ranges.selection.endDate),
            key: 'selection',
        });
        dispatch(Actions.updateValues({
            startDate: new Date(ranges.selection.startDate).toDateString(),
            endDate: new Date(ranges.selection.endDate).toDateString(),
        }, node, {}));
    }
    const addNewRoom = async (node: any) => {
        var data: any = [...roomArraangement];
        let i = roomArraangement.length;
        data.push({
            room_no: i,
            adults: 1,
            childrens: 0,
            childOneAge: -1,
            childTwoAge: -1,
            childThreeAge: -1
        })
        await setRoomArraangement(data);
        handleChildrens(1, node, data);
        handleAdults(1, node, data)
    }
    const deleteRoom = async (node: any, master: any) => {
        var data: any = [...roomArraangement];
        console.log("roomArraangement", roomArraangement, node);
        data = data.filter((n: any) => n.room_no !== node.room_no);
        console.log("roomArraangement data", data);
        await setRoomArraangement(data);
        handleChildrens(1, master, data);
        handleAdults(1, master, data)
    }
    const handleAdults = async (key: number, node: any, data = []) => {
        let count = 0;
        data.map((node: any) => {
            count += node.adults;
        })
        setAdultsCount(count)
        handleAnsSelection(count + childrensCount, node, {});
    }
    const handleChildrens = async (key: number, node: any, data = []) => {
        let count = 0;
        data.map((node: any) => {
            count += node.childrens;
        })
        await setChildrensCount(count)
        handleAnsSelection(adultsCount + count, node, {});
    }
    const updateCount = (flag: number, i: number, key: string, node: any) => {
        var data: any = [...roomArraangement];
        if (key === 'adult' && ((flag === 1 && data[i].adults + data[i].childrens < 5) || (flag === -1 && data[i].adults > 1))) {
            data[i].adults += flag;
            handleAdults(flag, node, data);
        } else if (key === 'children' && ((flag === 1 && data[i].childrens < 3 && data[i].adults + data[i].childrens < 5) || (flag === -1 && data[i].childrens > 0))) {
            data[i].childrens += flag;
            data[i].childrens = data[i].childrens <= 0 ? 0 : data[i].childrens;
            handleChildrens(flag, node, data);
        }
        setRoomArraangement(data);
    }
    const handleChildrenChange = (value: any, node: any, key: any) => {
        var data = [...roomArraangement];
        let index = data.findIndex((n: any) => n.room_no === node.room_no);
        if (index !== -1) {
            if (key === 1) {
                data[index].childOneAge = Number(value);
            } else if (key === 2) {
                data[index].childTwoAge = Number(value);
            } else {
                data[index].childThreeAge = Number(value);
            }
        }
        console.log("datadatadatadatadata",data);
        setRoomArraangement(data);
    }
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 4);
    return (
        <div>
            <div className="queDisplay">
                {index === Questions.length && node.loadSubSet ?
                    node?.subQuestions.map((subNode: any, key: any) => {
                        return <SubQuestions key={key} node={node} subNode={subNode} />
                    }) : null
                }
                <div className="MainQueSet">

                    <div className={node?.validResponse ? "MainQuestion animationFadeIn2" : "MainQuestion animationFadeIn1"}>
                        {showSummery ?
                            <div>
                                <h4>Submit your choices to receive the itinerary</h4>
                                <br />
                                <div className="summery">
                                    <div>Perfect!</div>
                                    <div><span>{Summary.name.value ? Summary.name.value : ''}</span>, You are going to the <span>{
                                        Summary.destination.value ? Summary.destination.value : ''
                                    }</span> from <span>{
                                        Summary.from.value ? Summary.from.value : ''
                                    }</span>,
                                        during the dates from <span>{Summary.startEndDate.value?.startDate}</span> to <span>{Summary.startEndDate.value?.endDate}</span>.
                                        You are going to travel in a group of &nbsp;
                                        <span>{childrensCount + adultsCount}</span>&nbsp;people:&nbsp;
                                        <span>{adultsCount}</span>&nbsp;Adult{adultsCount > 1 ? 's' : ''}, &nbsp;
                                        <span>{childrensCount}</span>&nbsp;Children{childrensCount > 1 ? 's' : ''} 
                                        {childrensCount > 0 ? <> of age <span>  {roomArraangement.map((node:any, index: any) => {
                                            return <span key={index}>
                                            {node.childOneAge >= 0 ? index !== 0 ? ", "+ node.childOneAge :  "" + node.childOneAge : ''}
                                            {node.childTwoAge >= 0 ? ", "+node.childTwoAge : ''}
                                            {node.childThreeAge >= 0 ? ", "+node.childThreeAge : ''}
                                        </span>
                                        })}</span></>:null}.
                                        Your total trip budget is up to <span>{
                                            Summary.budget.value ? Summary.budget.value : ''
                                        }</span>.</div>
                                    <br />
                                    <div>
                                        You are going on a &nbsp;<span>{
                                            Summary.occasion.value ? 
                                            Summary.occasion.value.length === 1 && Summary.occasion.value[0]=== 'Other' ?
                                            'Vacation' :
                                            Summary.occasion.value.filter((n:any) => {
                                                return n !== 'Other';
                                            }).map((node: any, index: any) => {
                                                return <span key={index}>
                                                    {index !== 0 ? Summary.occasion.value.filter((n:any) => {
                                                return n !== 'Other';
                                            }).length === index + 1 ? ' and ' : ', ' : ''}
                                                    <span>{node}</span>
                                                </span>
                                            }) : ''
                                        }</span> to enjoy &nbsp;
                                        {
                                            Summary.accomodationPreferences.value ? Summary.accomodationPreferences.value.map((node: any, index: any) => {
                                                return <span key={index}>
                                                    {index !== 0 ? Summary.accomodationPreferences.value.length === index + 1 ? ' and ' : ', ' : ''}
                                                    <span>{node}</span>
                                                </span>
                                            }) : ''
                                        }. You prefer &nbsp;
                                        {
                                            Summary.stay.value ? Summary.stay.value.map((node: any, index: any) => {
                                                return <span key={index}>
                                                    {index !== 0 ? Summary.stay.value.length === index + 1 ? ' and ' : ', ' : ''}
                                                    <span>{node}</span>
                                                </span>
                                            }) : ''
                                        }. <br /><br />
                                        <div>Your email id is <span>{Summary.email.value}</span> and your Phone Number is <span>{Summary.phone.value}</span></div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="tripLocation">
                                    {Summary.destination.value ?
                                        "Your Trip Profile to " + Summary.destination.value : ''}
                                </div>
                                <h4 className="h3Header">
                                    {node.id === "0x25892b5c5" ? node?.question.replace(/\?/g, '') : node?.question}&nbsp;
                                    {
                                        node.id === "0x25892b4e1" ? airSelectedStatus ? " (including flights)" : " (excluding flights)"
                                            : node.id === "0x25892b5c5" ? Summary.destination.value + ' ?' : null
                                    }
                                    {
                                        !node.skippable ?
                                            <span style={{ color: 'red' }}> *</span> : null
                                    }
                                    {/* *{node.id}-{node.key}* */}
                                    {/* **{node?.hide === 1 ? "true" : "false"}** */}
                                </h4>
                                {
                                    node?.questionInfo ?
                                        <div className="Explanation">{node?.questionInfo} </div>
                                        : null
                                }
                                <div className="maxArea">
                                    {
                                        node?.answerType === 'TextInput' || node.answerType === 'Email' || node.answerType === 'Phone' ?
                                            <div className="textBoxArea">
                                                <TextField value={node.selectedAns} onChange={(event) => handleAnsSelection(event.target.value, node, {})} id="outlined-basic" label={node.placeHolder} size="small" fullWidth={true} variant="outlined" />
                                            </div>
                                            :
                                            node?.answerType === 'FirstNameLastName' ?
                                                <div className="textBoxArea flex">
                                                    <div className="marginRight">
                                                        {/* {node.selectedAns1} + {node.selectedAns2} = {node.selectedAns} */}
                                                        <TextField value={node.selectedAns1} onChange={(event) => handleAnsSelection(event.target.value, node, {}, 'first')} id="outlined-basic" label="First name" size="small" fullWidth={true} variant="outlined" />
                                                    </div>
                                                    <div className="marginRight">
                                                        <TextField value={node.selectedAns2} onChange={(event) => handleAnsSelection(event.target.value, node, {}, 'last')} id="outlined-basic" label="Last name" size="small" fullWidth={true} variant="outlined" />
                                                    </div>
                                                </div>
                                                :

                                                node?.answerType === 'TextAreaInput' ?
                                                    <div className="textMBoxArea">
                                                        <TextField multiline minRows={4} maxRows={4} value={node.selectedAns} onChange={(event) => handleAnsSelection(event.target.value, node, {})} id="outlined-basic" label={node.placeHolder} size="small" fullWidth={true} variant="outlined" />
                                                    </div>
                                                    : node?.answerType === 'MultipleChoice' || node?.answerType === 'SingleChoice' ?
                                                        <span>
                                                            {
                                                                Object.keys(node.groupedList).map((key, i) => {
                                                                    return <div className={Object.keys(node.groupedList).length > 1 ? "NewLine" : ""}>
                                                                        {
                                                                            (node.groupedList[key]).sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((key: any, n: any) => {
                                                                                return <span title={key.tooltip} onClick={() => handleAnsSelection(key.value, node, {})} key={n} className={node.selectedAns ? (node.selectedAns.findIndex((n: any) => n === key.value) !== -1) ? "options selected" : "options" : "options"}>
                                                                                    {key.value}
                                                                                </span>
                                                                            })
                                                                        }
                                                                    </div>
                                                                })
                                                            }
                                                        </span>
                                                        : node?.answerType === 'MultipleChoiceTextInput' ?
                                                            <div>
                                                                <div>
                                                                    {
                                                                        Object.keys(node.groupedList).map((key, i) => {
                                                                            return <div className={Object.keys(node.groupedList).length > 1 ? "NewLine" : ""}>
                                                                                {
                                                                                    (node.groupedList[key]).sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((key: any, n: any) => {
                                                                                        return <span title={key.tooltip} onClick={() => handleAnsSelection(key.value, node, {})} key={n} className={node.selectedAns ? (node.selectedAns.findIndex((n: any) => n === key.value) !== -1) ? "options selected" : "options" : "options"}>
                                                                                            {key.value}
                                                                                        </span>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="textMBoxArea">
                                                                    <TextField value={node.selectedAns2} onChange={(event) => handleAnsSelection(event.target.value, node, {})} id="outlined-basic" label={node.invalidResponse ? node.invalidResponse : 'Other'} size="small" fullWidth={true} variant="outlined" />
                                                                </div>
                                                            </div>
                                                            : node?.answerType === 'DateRange' ?
                                                                <div style={{marginTop:"10px", marginBottom:"40px"}}>
                                                                    <TextField value={Moment(selectionRange.startDate).format('MMMM, DD MMM YYYY')} onFocus={()=>handleToggleHover(true)} id="outlined-basic" size="small" fullWidth={false} variant="outlined" />
                                                                    <TextField style={{marginLeft:"8px"}} value={Moment(selectionRange.endDate).format('MMMM, DD MMM YYYY')} onFocus={()=>handleToggleHover(true)} id="outlined-basic" size="small" fullWidth={false} variant="outlined" />
                                                                    {toggleHover?
                                                                    <div className="PopUpElement">
                                                                        <div onClick={()=>handleToggleHover(false)} className="popUpBG"></div>
                                                                        <div className="PopUpElementComp">
                                                                            <DateRange
                                                                                minDate={endDate}
                                                                                ranges={[selectionRange]}
                                                                                onChange={handleSelect}
                                                                                showDateDisplay={true}
                                                                                months={2}
                                                                                showMonthAndYearPickers={false}
                                                                                direction="horizontal"
                                                                            />
                                                                            <div style={{height:"51px"}}>
                                                                                <span onClick={()=>handleToggleHover(false)} className="NextButton okButton">Ok</span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    :null}
                                                                    <div style={{marginTop:"40px"}}>
                                                                        <div>
                                                                            Total {totalDaysSelected} Days selected
                                                                        </div>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox checked={dateFlexFlag} onChange={() => setDateFlexFlag(!dateFlexFlag)} name="gilad" />
                                                                            }
                                                                            label=""
                                                                        />My dates are flexible
                                                                        {dateFlexFlag ?
                                                                            <div className="passeng">
                                                                                <div className="actButton" onClick={() => handleFlexibleDate(-1, node)}>-</div>
                                                                                <div className="actCount">{flexibleDate} Day{flexibleDate > 1 ? 's' : ''}</div>
                                                                                <div className="actButton" onClick={() => handleFlexibleDate(1, node)}>+</div>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                                : node?.answerType === 'Passengers' ?
                                                                    <div className="Passengers">
                                                                        <div className="passeng">
                                                                            {/* <div className="actButton" onClick={() => handleAdults(-1, node)}>-</div> */}
                                                                            <div className="actCount">{adultsCount} Adult{adultsCount > 1 ? 's' : ''}</div>
                                                                            <div className="actCount">{childrensCount} Children{childrensCount > 1 ? 's' : ''}</div>
                                                                            {/* <div className={roomArraangement.length * 4 > (adultsCount + childrensCount) ? "actButton" : "actButton disabled"} onClick={() => roomArraangement.length * 4 > (adultsCount + childrensCount) ? handleAdults(1, node) : null}>+</div> */}
                                                                        </div>
                                                                        <div className="passeng">
                                                                            {/* <div className="actButton" onClick={() => handleChildrens(-1, node)}>-</div> */}
                                                                            {/* <div className="actCount">{childrensCount} Children{childrensCount > 1 ? 's' : ''}</div> */}
                                                                            {/* <div className={(roomArraangement.length * 2 > childrensCount) && (roomArraangement.length * 4 > (adultsCount + childrensCount)) ? "actButton" : "actButton disabled"} onClick={() => (roomArraangement.length * 2 > childrensCount) && (roomArraangement.length * 4 > (adultsCount + childrensCount)) ? handleChildrens(1, node) : null}>+</div> */}
                                                                        </div>
                                                                        <div className="RoomArrangement">
                                                                            <h4 className="flex">
                                                                                <span>Room Arrangement</span>
                                                                                <span onClick={() => addNewRoom(node)} className="PlusIconRight">
                                                                                    <div>+&nbsp;</div>
                                                                                    <span>Add</span>
                                                                                </span>
                                                                            </h4>
                                                                            <div className="flexRooms">
                                                                                {
                                                                                    roomArraangement.map((nd: any, index: number) => {
                                                                                        return <div className="rooms">
                                                                                            <div className="roomContainer">
                                                                                                <div className="roomHeader">
                                                                                                    <span className="roomsTitle">

                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><rect fill="none" height="24" width="24" /><path d="M7,21H3V8l13-5v7H7V21z M19,10c-1.1,0-2,0.9-2,2H9v9h5v-5h2v5h5v-9C21,10.9,20.1,10,19,10z" /></svg>

                                                                                                        <div>Room</div>
                                                                                                    </span>
                                                                                                    {index !== 0 ?
                                                                                                        <span onClick={() => deleteRoom(nd, node)} className="roomsRemove">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" /></svg>
                                                                                                        </span>
                                                                                                        : null}
                                                                                                </div>
                                                                                                <div className="flex roomAction">
                                                                                                    <div className="nme">Adult</div>
                                                                                                    <div className="actionBox">
                                                                                                        <div className={nd.adults <= 1 ? "roomBtn disabled" : "roomBtn"} onClick={() => updateCount(-1, index, 'adult', node)}>-</div>
                                                                                                        <div className="roomCount">{nd.adults}</div>
                                                                                                        <div className={nd.adults + nd.childrens >= 5 ? "roomBtn disabled" : "roomBtn"} onClick={() => updateCount(1, index, 'adult', node)}>+</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="flex roomAction">
                                                                                                    <div className="nme">Children</div>
                                                                                                    <div className="actionBox">
                                                                                                        <div className={nd.childrens <= 0 ? "roomBtn disabled" : "roomBtn"} onClick={() => updateCount(-1, index, 'children', node)}>-</div>
                                                                                                        <div className="roomCount">{nd.childrens}</div>
                                                                                                        <div className={nd.adults + nd.childrens >= 5 || nd.childrens >= 3 ? "roomBtn disabled" : "roomBtn"} onClick={() => updateCount(1, index, 'children', node)}>+</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    nd.childrens === 1 || nd.childrens === 2 || nd.childrens === 3 ?
                                                                                                        <div className="ChildrenAgeSet" >
                                                                                                            <div className="ChildAge">First child age</div>
                                                                                                            <select onChange={(event) => handleChildrenChange(event.target.value, nd, 1)} value={nd.childOneAge}>
                                                                                                                {
                                                                                                                    ageGroup.map((age: any) => {
                                                                                                                        return <option key={age} value={age}>{age}</option>
                                                                                                                    })
                                                                                                                }
                                                                                                            </select>

                                                                                                        </div>
                                                                                                        : null
                                                                                                }
                                                                                                {nd.childrens === 2 || nd.childrens === 3 ?
                                                                                                    <div className="ChildrenAgeSet" >
                                                                                                        <div className="ChildAge">Second child age</div>
                                                                                                        <select onChange={(event) => handleChildrenChange(event.target.value, nd, 2)} value={nd.childTwoAge}>
                                                                                                            {
                                                                                                                ageGroup.map((age: any) => {
                                                                                                                    return <option key={age} value={age}>{age}</option>
                                                                                                                })
                                                                                                            }
                                                                                                        </select>

                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                                {nd.childrens === 3 ?
                                                                                                    <div className="ChildrenAgeSet" >
                                                                                                        <div className="ChildAge">Third child age</div>
                                                                                                        <select onChange={(event) => handleChildrenChange(event.target.value, nd, 3)} value={nd.childThreeAge}>
                                                                                                            {
                                                                                                                ageGroup.map((age: any) => {
                                                                                                                    return <option key={age} value={age}>{age}</option>
                                                                                                                })
                                                                                                            }
                                                                                                        </select>

                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                                {nd.adults + nd.childrens >= 3 ?
                                                                                                    <div className="roomInfo">
                                                                                                        Not all hotels offer rooms which accommodate 3 or more guests. Please consider adding more rooms to see more hotel options and receive better prices
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : node?.answerType === 'Toggler' ?
                                                                        <div className="passeng">
                                                                            <div className="actButton" onClick={() => handleRooms(-1, node)}>-</div>
                                                                            <div className="actCount">{rooms} Room{rooms > 1 ? 's' : ''}</div>
                                                                            <div className="actButton" onClick={() => handleRooms(1, node)}>+</div>
                                                                        </div> : null
                                    }
                                    {
                                        node?.subQueList ? node?.subQueList.map((subNode: any, kk: any) => {
                                            return <div>
                                                <h4 className="SubTitle">{subNode?.question} {
                                                    !subNode.skippable ?
                                                        <span style={{ color: 'red' }}> *</span> : null
                                                }</h4>
                                                {/* *{subNode.id}-{subNode.key}* */}
                                                {
                                                    subNode?.questionInfo ?
                                                        <div className="Explanation">{subNode?.questionInfo} </div>
                                                        : null
                                                }
                                                {subNode.answerType === 'Phone' ?
                                                    <div className="textBoxArea">
                                                        <PhoneInput
                                                            country={'us'}
                                                            inputProps={{
                                                                name: 'phone',
                                                                required: true
                                                            }}
                                                            value={subNode.selectedAns}
                                                            onChange={phone => handleAnsSelection(phone, subNode, node)}
                                                        />
                                                        {/* <TextField value={subNode.selectedAns} onChange={(event) => handleAnsSelection(event.target.value, subNode, node)} id="outlined-basic" label={subNode.invalidResponse ? subNode.invalidResponse : 'Please write your Answer'} size="small" fullWidth={true} variant="outlined" /> */}
                                                    </div>
                                                    : subNode?.answerType === 'MultipleChoice' || subNode?.answerType === 'SingleChoice' ?
                                                        subNode.answerChoices.map((key: any, n: any) => {
                                                            return <span onClick={() => handleAnsSelection(key.value, subNode, node)} key={n} className={subNode.selectedAns ? (subNode.selectedAns.findIndex((n: any) => n === key.value) !== -1) ? "options selected" : "options" : "options"}>
                                                                {key.value}
                                                            </span>
                                                        })
                                                        : subNode?.answerType === 'TextAreaInput' ?
                                                            <div className="textMBoxArea">
                                                                <TextField multiline minRows={4} maxRows={4} value={subNode.selectedAns} onChange={(event) => handleAnsSelection(event.target.value, subNode, node)} id="outlined-basic" label={subNode.placeHolder} size="small" fullWidth={true} variant="outlined" />
                                                            </div>
                                                            : subNode?.answerType === 'DateRange' ?
                                                                <DateRange
                                                                    ranges={[selectionRange]}
                                                                    onChange={handleSelect}
                                                                />
                                                                : subNode?.answerType === 'Passengers' ?
                                                                    <div className="Passengers">
                                                                        <div className="passeng">
                                                                            <div className="actButton" onClick={() => handleAdults(-1, subNode)}>-</div>
                                                                            <div className="actCount">{adultsCount} Adult{adultsCount > 1 ? 's' : ''}</div>
                                                                            <div className="actButton" onClick={() => handleAdults(1, subNode)}>+</div>
                                                                        </div>
                                                                        <div className="passeng">
                                                                            <div className="actButton" onClick={() => handleChildrens(-1, subNode)}>-</div>
                                                                            <div className="actCount">{childrensCount} Children{childrensCount > 1 ? 's' : ''}</div>
                                                                            <div className="actButton" onClick={() => handleChildrens(1, subNode)}>+</div>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                }</div>
                                        })
                                            //Loop End
                                            : null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={node?.validResponse ? "moveToNext animationFadeIn2" : "moveToNext animationFadeIn1"}>
                    <div className="errorValidation">
                        {validation}
                    </div>
                    {/* <div className="selectedAns">
                        {
                            node.selectedAns ?
                                node?.answerType === 'MultipleChoice' ?
                                    node.selectedAns.map((S: any, index: number) => {
                                        return <span key={index}>
                                            {index > 0 ? '; ' : null}
                                            {S}
                                        </span>
                                    })
                                    : node?.answerType === 'TextInput' ?
                                        node.selectedAns
                                        : null
                                : null
                        }
                    </div> */}
                    {index !== 1 ?
                        <span onClick={() => moveToBack(index)} className="BackButton">Back</span>
                        : null}
                    {
                        node.skippable && (!node.selectedAns || node.selectedAns === null || node?.selectedAns.length === 0) && !showSummery ?
                            <span onClick={() => setAnsSelection(node)} className="NextButton">
                                {node.selectedAns2 && node.selectedAns2 !== '' && node.selectedAns !== '' ?
                                    "Next" : "Skip"
                                }
                            </span>
                            : null
                    }
                    {!showSummery && !(node.skippable && (!node.selectedAns || node.selectedAns === null || node.selectedAns?.length === 0)) ?
                        // !node.status ?
                        <span onClick={() => setAnsSelection(node)} className="NextButton">

                            Next</span>
                        // :
                        // <span className="NextButton">Change my answer</span>
                        : null
                    }
                    {showSummery ?
                        // !node.status ?
                        <span onClick={() => submitData()} className={!submitAPIFlag ? "NextButton" : "notAllowed"}>
                            {submitAPIFlag ? <div className="inlineloader"></div> : null}
                            {submitAPIFlag ? "Submitting" : "Submit"}
                        </span>
                        // :
                        // <span className="NextButton">Change my answer</span>
                        : null
                    }
                </div>
            </div>
        </div>
    );
}
export default NestedQue;