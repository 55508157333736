
import { gql } from '@apollo/client';

export const ERROR_HANDLE = gql`mutation MyMutation
(
  $answerJson:String,
  $errorJson: String
) {
  addErrorTravelAnswer(input: {
    answerJson: $answerJson,
    errorJson: $errorJson
  }) {
    numUids
  }
}`;

export const SUBMIT_QUESTION = gql`mutation MyMutation
(
$name: String!,
$phone: String!,
$email: String!,
$from: String!,
$destination: String!,
$startDate: String!,
$endDate: String!,
$adultsCount: Int!,
$budget: String!,
$vacationType: [String!]
$submitTime: DateTime,
$roomRequirement: [String],
$resortRquirements: [String],
$previouslyStayedResortInfo: String,
$kidsCount: Int,
$interests: [String],
$bookingItems:[String],
$importantInfo: String,
$comment: String,
$roomInfo:[RoomInfoRef]
$tripDateFelxibility: Int,
$summary: String,
$amenities: [String]) {
  addTravelAnswer(input: {name: $name, 
    phone: $phone, 
    email: $email, 
    from: $from, 
    destination: $destination, 
    startDate: $startDate, 
    endDate: $endDate, 
    adultsCount: $adultsCount, 
    budget: $budget, 
    vacationType: $vacationType, 
    submitTime: $submitTime, 
    roomRequirement: $roomRequirement, 
    resortRquirements: $resortRquirements, 
    previouslyStayedResortInfo: $previouslyStayedResortInfo, 
    kidsCount: $kidsCount, 
    interests: $interests, 
    bookingItems: $bookingItems,
    importantInfo: $importantInfo, 
    comment: $comment, 
    roomInfo: $roomInfo,
    tripDateFelxibility:$tripDateFelxibility,
    summary:$summary,
    amenities: $amenities}) {
    numUids
  }
}`;
