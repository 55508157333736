import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Actions from '../store/actions'
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { LOAD_INIT_QUESTION, LOAD_NEXT_QUESTION, LOAD_CATEGORIES, NextQue } from "../graphQL/queries";

const SubQuestions = (props: any) => {
    const { subNode, node } = props;
    const dispatch = useDispatch();
    const { data: initData, loading } = useQuery(LOAD_NEXT_QUESTION, {
        variables: { id: subNode.id }
    });
    const updateDOM = async (initData: any) => {
        if (initData) {
            console.log("get Sub", subNode);
            // @ts-ignore
            if (initData?.queryTravelQuestion.length >= 1) {
                 // @ts-ignore
                 await dispatch(Actions.appendSubQuestion(initData?.queryTravelQuestion[0], node))
            }
        }
    }
    useEffect(() => {
        updateDOM(initData);
    }, [initData]);
    return <div>
    </div>

}
export default SubQuestions;